/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import * as Styled from "./styles";

export default function CircleProfiler({
    onClick = () => {},
    type = "",
    width,
    flagOnClick = false,
    color,
    small = false,
    medium = false,
    large = false,
    fontSize = 13,
    ...rest
}) {
    // eslint-disable-next-line no-unused-vars
    const [newType, setType] = useState(type);
    const textCircleRef = useRef();

    useEffect(() => {
        setType(type);
    }, [type]);

    useEffect(() => {
        if (textCircleRef.current) {
            textCircleRef.current.setAttribute("translate", "no");
        }
    }, []);

    const isType = () =>
        type === "Bloqueado" ||
        type === "Expirado" ||
        type === "Não respondido" ||
        !type;

    return isType() ? (
        <Styled.InfoTextAux
            type={type}
            length={newType && newType.length}
            onClick={() => onClick()}
            color={color}
            {...rest}
            onMouseOver={e => type === "Bloqueado" && setType("Desbloquear")}
            onMouseLeave={e => type === "Bloqueado" && setType("Bloqueado")}
            flagOnClick={flagOnClick}
            fontSize={fontSize}
        >
            {newType}
        </Styled.InfoTextAux>
    ) : (
        <Styled.CircleProfilerContentAux
            onClick={() => onClick()}
            flagOnClick={flagOnClick}
            width={width}
            large={large}
            medium={medium}
            small={small}
            type={type}
        >
            <Styled.CircleType
                large={large}
                medium={medium}
                small={small}
                type={type}
            >
                <Styled.TextCircle ref={textCircleRef}> {type} </Styled.TextCircle>
            </Styled.CircleType>
        </Styled.CircleProfilerContentAux>
    );
}
